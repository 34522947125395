import React, { Component } from "react";
import { gsap } from "gsap"
import { window } from "browser-monads"
import WhereWillYouExplore from '../images/where-will-you-explore.svg'


export default class Cursor extends Component {
  constructor(props) {
    super(props)

    this.state = {
      text: "View",
      isPlayingVideo: false,
      isHidden: false
    }

    const DEFAULT_DURATION = 0.5
    const DEFAULT_EASE = "power4.inOut"
    const FULL_SIZE = 125
    const SHRINK_SIZE = 25
    const POINTER_SIZE = 12

    this.cursorVideoHover = {
      width: FULL_SIZE,
      height: FULL_SIZE,
      left: -(FULL_SIZE / 2),
      top: -(FULL_SIZE / 2),
      duration: DEFAULT_DURATION,
      ease: DEFAULT_EASE,
    }

    this.cursorPointer = {
      width: POINTER_SIZE,
      height: POINTER_SIZE,
      top: -(POINTER_SIZE / 2),
      left: -(POINTER_SIZE / 2),
      duration: DEFAULT_DURATION,
      ease: DEFAULT_EASE
    }

    this.textVisible = {
      opacity: 1,
      scale: 1,
      ease: DEFAULT_EASE,
      duration: DEFAULT_DURATION
    }

    this.textHidden = {
      opacity: 0,
      scale: 0.5,
      ease: DEFAULT_EASE,
      duration: DEFAULT_DURATION
    }

    this.defaultCursor = {
      width: SHRINK_SIZE,
      height: SHRINK_SIZE,
      top: -(SHRINK_SIZE / 2),
      left: -(SHRINK_SIZE / 2),
      duration: DEFAULT_DURATION,
      ease: DEFAULT_EASE
    }
  }

  setupWhereWillYouExploreCursor() {
    document.querySelector('.custom-cursor').classList.remove('custom-cursor--filled')
    document.querySelector('.custom-cursor').classList.remove('custom-cursor--pointer')
    document.querySelector('.custom-cursor').classList.add('custom-cursor--explore')
    gsap.to('.custom-cursor', this.cursorVideoHover)
    gsap.to('.custom-cursor__text', this.textHidden)
  }

  setupPlayVideoCursor() {
    this.setState({
      text: "Play"
    })
    document.querySelector('.custom-cursor').classList.remove('custom-cursor--explore')
    document.querySelector('.custom-cursor').classList.remove('custom-cursor--pointer')
    document.querySelector('.custom-cursor').classList.add('custom-cursor--filled')
    gsap.to('.custom-cursor', this.cursorVideoHover)
    gsap.to('.custom-cursor__text', this.textVisible)
  }

  setupCloseVideoCursor() {
    this.setState({
      text: "Close"
    })
    document.querySelector('.custom-cursor').classList.remove('custom-cursor--explore')
    document.querySelector('.custom-cursor').classList.remove('custom-cursor--pointer')
    document.querySelector('.custom-cursor').classList.remove('custom-cursor--filled')
    gsap.to('.custom-cursor', this.cursorVideoHover)
    gsap.to('.custom-cursor__text', this.textVisible)
  }

  setupPauseVideoCursor() {
    this.setState({
      text: "Pause"
    })
    document.querySelector('.custom-cursor').classList.remove('custom-cursor--explore')
    document.querySelector('.custom-cursor').classList.remove('custom-cursor--pointer')
    document.querySelector('.custom-cursor').classList.remove('custom-cursor--filled')
    gsap.to('.custom-cursor', this.cursorVideoHover)
    gsap.to('.custom-cursor__text', this.textVisible)
  }

  setupDefaultCursor() {
    document.querySelector('.custom-cursor').classList.remove('custom-cursor--explore')
    document.querySelector('.custom-cursor').classList.remove('custom-cursor--pointer')
    document.querySelector('.custom-cursor').classList.remove('custom-cursor--filled')
    gsap.to('.custom-cursor', this.defaultCursor)
    gsap.to('.custom-cursor__text', this.textHidden)
  }

  setupPointerCursor() {
    document.querySelector('.custom-cursor').classList.remove('custom-cursor--explore')
    document.querySelector('.custom-cursor').classList.remove('custom-cursor--filled')
    document.querySelector('.custom-cursor').classList.add('custom-cursor--pointer')
    gsap.to('.custom-cursor', this.cursorPointer)
    gsap.to('.custom-cursor__text', this.textHidden)
  }

  componentDidMount() {

    gsap.to('.custom-cursor-explore img', {
      rotate: 360,
      ease: "none",
      duration: 5,
      repeat: -1
    })

    window.onmousemove = (event) => {
      gsap.to('.custom-cursor', {
        x: event.clientX,
        y: event.clientY,
        duration: 0.5,
      })
    }

    window.addEventListener('enter:globe', () => {
      this.setupWhereWillYouExploreCursor()
    })

    window.addEventListener('leave:globe', () => {
      this.setupDefaultCursor()
    })

    window.addEventListener('play:hero-video', () => {
      this.setState({
        isPlayingVideo: true
      })
      this.setupPauseVideoCursor()
    })

    window.addEventListener('pause:hero-video', () => {
      console.log('catch pause:hero-video')
      this.setState({
        isPlayingVideo: false
      })
      this.setupPlayVideoCursor()
    })

    window.addEventListener('open:video-box', () => {
      setTimeout(() => {
        this.setupCloseVideoCursor()
      }, 300)
    })

    window.addEventListener('scrolled-past:hero-video', () => {
      this.setState({
        isPlayingVideo: false
      })
      console.log('catch scrolled-past:hero-video')
      setTimeout(() => {
        this.setupDefaultCursor()
      }, 300)
    })

    window.addEventListener('close:video-box', () => {
      this.setupDefaultCursor()
    })

    // Menu
    window.addEventListener('open:menu', () => {
      this.setupCloseVideoCursor()
    })

    window.addEventListener('close:menu', () => {
      this.setupDefaultCursor()
    })

    // Video
    const videoItems = document.querySelectorAll('.custom-video, .join-movement-video')
    videoItems.forEach(item => {
      item.addEventListener('mouseenter', () => {
        console.log(this.state.isPlayingVideo)
        if (this.state.isPlayingVideo) {
          this.setupPauseVideoCursor()
        } else {
          this.setupPlayVideoCursor()
        }
      })
      item.addEventListener('mouseleave', () => {
        this.setupDefaultCursor()
      })
    })

    // Video Event Items
    const hiddenCursorItems = document.querySelectorAll('.video-event-item, .home-download-buttons .download-app-store, .home-download-buttons .download-google-play,  .menu-open-button')
    hiddenCursorItems.forEach(item => {
      item.addEventListener('mouseenter', () => {
        this.setupPointerCursor()
      })
      item.addEventListener('mouseleave', () => {
        this.setupDefaultCursor()
      })
    })

    // Nav Menu Items
    const navMenuItems = document.querySelectorAll('.main-menu .main-items, .main-menu .sub-items, .main-menu .download-app-store, .main-menu .download-google-play')
    navMenuItems.forEach(item => {
      item.addEventListener('mouseenter', () => {
        this.setupPointerCursor()
      })
      item.addEventListener('mouseleave', () => {
        this.setupCloseVideoCursor()
      })
    })

  }

  render() {
    return (
      <div className={`custom-cursor ${this.state.isHidden ? 'custom-cursor--hidden' : ''}`}>
        <div className="custom-cursor-explore" style={{
          opacity: 0
        }}>
          <img src={WhereWillYouExplore} />
        </div>
        <svg width="50" height="50" viewBox="0 0 50 50" fill="none" xmlns="http://www.w3.org/2000/svg">
          <circle cx="25" cy="25" r="24.5" fill="#fff" />
        </svg>
        <div className="custom-cursor__text" style={{
          opacity: 0,
          scale: 0.5
        }}>
          {this.state.text}
        </div>
      </div>
    )
  }
}
