import React, { Component } from "react";
import Vimeo from '@u-wave/react-vimeo';
import gsap from 'gsap';
import { window } from "browser-monads";

export default class CustomVideo extends Component {
  constructor(props) {
    super(props)

    this.state = {
      paused: true
    }

    this.EASE = "power4.inOut"
    this.DURATION = 0.5

    this.hide = {
      opacity: 0,
      duration: this.DURATION,
      ease: this.EASE
    }

    this.show = {
      opacity: 1,
      duration: this.DURATION,
      ease: this.EASE
    }

    this.shadowShow = {
      opacity: 0.5,
      duration: this.DURATION,
      ease: this.EASE
    }
  }

  componentDidMount() {
    window.addEventListener('scrolled-past:hero-video', () => {
      gsap.to('.video-one-el', this.show)
      gsap.to('.video-two-el', this.hide)
      gsap.to('.video-shadow', this.shadowShow)
      this.setState({
        paused: true
      })
    })
  }

  render() {
    return (
      // 493005291
      <section className="custom-video relative z-0 overflow-hidden min-h-screen" onClick={() => {
        if (this.state.paused) {
          gsap.to('.video-one-el', this.hide)
          gsap.to('.video-two-el', this.show)
          gsap.to('.video-shadow', this.hide)
          this.setState({
            paused: false
          })
          window.dispatchEvent(new Event('play:hero-video'))
        } else {
          gsap.to('.video-one-el', this.show)
          gsap.to('.video-two-el', this.hide)
          gsap.to('.video-shadow', this.shadowShow)
          this.setState({
            paused: true
          })
          window.dispatchEvent(new Event('pause:hero-video'))
        }

      }}>
        <div className="video-shadow absolute top-0 left-0 w-full h-full bg-dark-purple opacity-50" style={{
          zIndex: 2
        }}></div>
        <div className="video-one-el" data-scroll data-scroll-speed="-3">
          <Vimeo video={"493026805"}
            style={{
              pointerEvents: "none",
              scale: 1.2
            }}
            autoPlay={true}
            muted={true}
            controls={false}
            background={true}
            loop={true}
            autopause={false}
            responsive={true}
          />

        </div>
        <div className="video-two-el" data-scroll data-scroll-speed="-3">
          <Vimeo video={"493005291"}
            width='100%'
            height='100%'
            volume={1}
            paused={this.state.paused}
            controls={false}
            loop={true}
            autopause={false}
            responsive={true}
          />

        </div>


      </section>
    )
  }
}
